export const countryListData =[
    {
        value:"United States",
        countryImage:"image",
        countryCode2:"US",
        countryCode3:"USA"
    },
    {
        value:"Canada",
        countryImage:"CA",
        countryCode2:"CA",
        countryCode3:"CA" 
    },
    {
        value:"Pakistan",
        countryImage:"PK",
        countryCode2:"Pk",
        countryCode3:"PK" 
    },
    {
        value:"Qatar",
        countryImage:"QK",
        countryCode2:"Qk",
        countryCode3:"QK" 
    }
]