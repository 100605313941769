import React from 'react'
import CampaignCreation from '../components/campaign-creation/CampaignCreation'


const CreateCampaign = () => {
  return (
    <div><CampaignCreation /></div>
  )
}

export default CreateCampaign