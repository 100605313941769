import React from 'react'
import NftCampaignDetailContent from '../components/campaign-detail-component/NftCampaignDetailContent'

const NftCampaignDetail = () => {
  return (
    <>
    <NftCampaignDetailContent />
    </>
  )
}

export default NftCampaignDetail