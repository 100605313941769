import React from 'react'
import Header from '../common/ui-components/Header'

const SettingContents = () => {
  return (
    <>
    <Header title='Settings' />
    </>
  )
}

export default SettingContents