import React from 'react'
import Header from '../common/ui-components/Header'

const DraftContent = () => {
  return (
    <>
    <Header title='Drafts' />
    </>
  )
}

export default DraftContent