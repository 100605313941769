import React from 'react'

const ClaimUtilityFooter = () => {
  return (
    <>
    <div className='py-8'>
        <p className='text-[#8D9D91] text-xs'> © {new Date().getFullYear()}. All rights reserved | Privacy Policy</p>

    </div>
         </>
  )
}

export default ClaimUtilityFooter